import React, {Component, useEffect, useState} from "react";

import {connect, useDispatch} from "react-redux";
import {
    Row,
    Col,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Input,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap";

import {Link} from "react-router-dom";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//Import i18n
import {withNamespaces} from "react-i18next";

//Import logo Images
import logo from "../../assets/images/logo/agronomy.png";
import logosm from "../../assets/images/logo/agronomy_icon.png"
import logoChristmas from "../../assets/images/logo/agronomy_christmas.png";

const Header = (props) => {

    const christmas = () => {
        const date = new Date();
        const christmas = new Date(date.getFullYear(), 10, 20);

        if (date > christmas) {
            return logoChristmas
        }

        return logo
    }

    const toggleFullscreen = () => {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    return (
        <>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box"
                             style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Link to="#" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logosm} alt="" width="100%"/>
                                    </span>
                                <span className="logo-lg">
                                        <img src={christmas()} alt="" width="100%"/>
                                    </span>
                            </Link>

                            <Link to="#" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logosm} alt="" width="100%"/>
                                    </span>
                                <span className="logo-lg">
                                        <img src={christmas()} alt="" width="100%"/>
                                    </span>
                            </Link>
                        </div>
                        <Button size="sm" color="none" type="button" onClick={props.toggleMenuCallback}
                                className="px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                            <i className="ri-menu-2-line align-middle"/>
                        </Button>
                    </div>

                    <div className="d-flex">

                        <LanguageDropdown/>

                        <div className="dropdown d-none d-lg-inline-block ms-1">
                            <Button color="none" type="button" className="header-item noti-icon waves-effect"
                                    onClick={toggleFullscreen}>
                                <i className="ri-fullscreen-line"></i>
                            </Button>
                        </div>

                        <ProfileMenu/>
                    </div>
                </div>
            </header>
        </>
    )
}

export default withNamespaces()(Header);
