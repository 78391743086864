import React, { useEffect } from "react";

// Reactstrap Components
import { Row, Col, Input, Button, Container, Label } from "reactstrap";

// Redux
import { useDispatch } from "react-redux";

// React Router
import { withRouter, Link } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

import logo from "../../assets/images/logo/agronomy.png";
import logoChristmas from "../../assets/images/logo/agronomy_christmas.png";

import { login } from "../../features/userSlice";

import toastr from "toastr";

const Login = (props) => {
  const dispatch = useDispatch();

  /**
   * @desc Login Event
   * @param event
   * @param values
   */
  const handleSubmit = (event, values) => {
    dispatch(login(values)).then((response) => {
      if (response.error?.message === "Rejected") {
        toastr.error(response.payload.error, "Error");
      } else {
        return props.history.push("/dashboard");
      }
    });
  };

  const christmas = () => {
    const date = new Date();
    const christmas = new Date(date.getFullYear(), 10, 20);

    if (date > christmas) {
      return logoChristmas;
    }

    return logo;
  };

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
  }, []);

  return (
    <>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} className="bg-dark">
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/">
                              <img
                                src={christmas()}
                                alt=""
                                width="80%"
                                className="auth-logo logo-dark mx-auto"
                              />
                              <img
                                src={christmas()}
                                alt=""
                                width="80%"
                                className="auth-logo logo-light mx-auto"
                              />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4 text-white">
                            Bienvenido!
                          </h4>
                          <p className="text-white">
                            Digita tus credenciales para ingresar.
                          </p>
                        </div>

                        <div className="p-2">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={handleSubmit}
                          >
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon" />
                              <Label htmlFor="username">
                                Correo Electronico
                              </Label>
                              <AvField
                                name="username"
                                type="text"
                                className="form-control"
                                id="username"
                                validate={{ email: true, required: true }}
                                placeholder="Digite su correo electronico..."
                              />
                            </div>

                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon" />
                              <Label htmlFor="userpassword">Contraseña</Label>
                              <AvField
                                name="password"
                                type="password"
                                className="form-control"
                                id="userpassword"
                                placeholder="Digite su contraseña..."
                              />
                            </div>

                            <div className="form-check">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <Label
                                className="form-check-label text-white"
                                htmlFor="customControlInline"
                              >
                                Recordarme
                              </Label>
                            </div>

                            <div className="mt-4 text-center">
                              <Button
                                color="primary"
                                className="w-md waves-effect waves-light text-white"
                                type="submit"
                              >
                                Ingresar
                              </Button>
                            </div>

                            <div className="mt-4 text-center text-white">
                              <Link
                                to="/forgot-password"
                                className="text-white"
                              >
                                <i className="mdi mdi-lock me-1 text-white" />{" "}
                                Olvidaste tu contraseña?
                              </Link>
                            </div>
                          </AvForm>
                        </div>

                        <div className="mt-5 text-center text-white">
                          <p>
                            © {new Date().getFullYear()}{" "}
                            <a
                              href="https://izeven.com/"
                              className="text-white"
                            >
                              iZeven LLC.
                            </a>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                {/* <div className="bg-overlay"/> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(Login);
