import React, { Component, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  CardColumns,
  Card,
  CardImg,
  CardBody,
  CardText,
  Button,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { withNamespaces } from "react-i18next";
import axios from "axios";
import moment from "moment";
import { NavLink } from "react-router-dom";
import Preloader from "../../components/Preloader";

const Projects = (props) => {
  const breadcrumbItems = [
    { title: "Agronomy", link: "/" },
    { title: "Projects", link: "#" },
  ];

  const [projects, setProjects] = useState([]);

  const [loading, setLoading] = useState(true);

  const getProjects = async () => {
    await axios
      .get(process.env.REACT_APP_AGRONOMY_URL + "api/projects")
      .then((response) => {
        setProjects(response.data.projects);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getProjects().then(() => setLoading(false));
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Projects" breadcrumbItems={breadcrumbItems} />
          {loading ? (
            <Preloader />
          ) : (
            <CardColumns>
              {projects.map((project) => (
                <Card>
                  <CardImg top src={project.images[0].path} alt="Skote" />
                  <CardBody>
                    <h3 className="text-primary">{project.name}</h3>
                    <p>
                      <i className="text-primary ri-map-pin-3-fill" />{" "}
                      {project.ubication.city +
                        ", " +
                        project.ubication.state +
                        ", " +
                        project.ubication.country}
                    </p>
                    <hr />
                    <Row>
                      <Col md={6}>
                        <div>
                          <h4 className="text-center mb-0">
                            {project.current
                              ? project.current.available_capital_units
                              : ""}{" "}
                            /{" "}
                            <small className="text-muted">
                              {project.capital_units}
                            </small>
                          </h4>
                          <p className="text-center text-muted">
                            {props.t("Available Capital Units")}
                          </p>
                        </div>
                      </Col>
                      <Col
                        md={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <h4 className="text-center text-primary mb-0">
                            ${" "}
                            {project.current
                              ? project.current.capital_unit_value
                              : ""}
                            <small>USD</small>
                          </h4>
                          <p className="text-center text-muted">
                            {props.t("Per Capital Unit")}
                          </p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <NavLink
                          as="a"
                          to={"/project/" + project.id}
                          className="btn btn-primary btn-block"
                          style={{ width: "100%" }}
                        >
                          {props.t("See Details")}
                        </NavLink>
                      </Col>
                    </Row>
                    <CardText className="pt-2">
                      <small className="text-muted">
                        {props.t("Published")}{" "}
                        {moment(project.created_at).fromNow()}
                      </small>
                    </CardText>
                  </CardBody>
                </Card>
              ))}
            </CardColumns>
          )}
        </Container>
      </div>
    </>
  );
};

export default withNamespaces()(Projects);
