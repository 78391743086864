import React, {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";

const CapitalUnitValue = () => {

    const [series, setSeries] = useState([
        {
            name: 'Value',
            type: 'line',
            data: []
        }
    ]);

    const [options, setOptions] = useState({
        chart: {
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'straight'
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        colors: ['#71E002'],
        labels: ['Nov 09, 2021 ', 'Mar 10, 2022']
    });

    const getData = async () => {
        await axios.get(process.env.REACT_APP_AGRONOMY_URL + 'api/stats/project_capital_unit_value/1')
            .then(response => {
                setSeries([
                    {
                        name: 'Value',
                        type: 'line',
                        data: response.data
                    }
                ]);
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <ReactApexChart options={options} series={series} type="line" height="280" width="100%"/>
    )
}

export default CapitalUnitValue;
