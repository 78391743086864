import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Progress } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import ProjectValue from "./Components/ProjectValue";
import CapitalUnitValue from "./Components/CapitalUnitValue";
import Plusvalue from "./Components/Plusvalue";

const Dashboard = () => {
  const breadcrumbItems = [
    { title: "Agronomy", link: "/" },
    { title: "Dashboard", link: "#" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">
                        Crecimiento de Valor del Proyecto Cultivo de Arándanos
                        Orgánicos
                      </h4>
                      <ProjectValue />
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">
                        Crecimiento de Valor de la Unidad de Capital de Cultivo
                        de Arándanos Orgánicos
                      </h4>
                      <CapitalUnitValue />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Card>
                <CardBody>
                  <h5 className="text-primary">
                    Percentage of Project Progress{" "}
                    <small>
                      <span className="text-secondary">(Aprox.)</span>
                    </small>
                  </h5>
                  <hr />
                  <Progress
                    value={10}
                    color="#71E002"
                    style={{
                      height: "30px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    animated
                  >
                    1 %
                  </Progress>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Plusvalue />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
