import React, {useRef} from "react";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

//Icons
import contract from '../../../assets/images/icons/contract.png'
import money from '../../../assets/images/icons/transfer-money.png'

import {withNamespaces} from "react-i18next";
import convertToBase64 from "../../../helpers/Convert/base64";
import axios from "axios";
import {toast} from "react-toastify";

const UploadDoc = ({show, toggle, block, getBlocks, ...props}) => {

    const contractRef = useRef();
    const transferRef = useRef();

    const uploadContract = () => {
        contractRef.current.click();
    }

    const uploadTransfer = () => {
        transferRef.current.click();
    }

    const handleChange = (e, doc) => {
        const file = e.target.files[0]
        convertToBase64(file)
            .then(response => {
                uploadDocument({
                    block_id: block.id,
                    document: doc,
                    file: response
                })
            })
    }

    const uploadDocument = async (data) => {
        await axios.post(process.env.REACT_APP_AGRONOMY_URL + 'api/blocks/upload/doc', data)
            .then(response => {
                toast.success(props.t('The document was successfully uploaded.'), {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                getBlocks();
                toggle();
            })
            .catch(error => console.log(error))
    }

    return (
        <Modal isOpen={show} toggle={toggle} size="md">
            <ModalHeader toggle={toggle}/>
            <ModalBody>
                <Row className="align-items-center">
                    <Col md={12}>
                        <div className="doc-card border-primary">
                            <Row>
                                <Col xs={4}>
                                    <img src={contract} width="100%"/>
                                </Col>
                                <Col xs={8}>
                                    <h4 className="text-primary">{props.t("Upload your contract.")}</h4>
                                    <p>
                                        {props.t("Upload your completed contract here so that our team can review it and approve your Capital Units.")}
                                    </p>
                                    <div style={{width: '100%', height: '31px'}}>
                                        {
                                            block.contract ?
                                                <a onClick={uploadContract} href="#" className="btn btn-block btn-agronomy-outline"><i
                                                    className="fas fa-check-circle"/> File Uploaded.</a> :
                                                <a onClick={uploadContract} href="#"
                                                   className="btn btn-block btn-agronomy-outline">Click to upload a
                                                    file.</a>
                                        }
                                    </div>
                                    <input onChange={(e) => handleChange(e, 'Contract')} ref={contractRef} type="file" style={{display: 'none'}}/>
                                </Col>
                            </Row>
                        </div>
                        <div className="doc-card border-primary">
                            <Row>
                                <Col xs={4}>
                                    <img src={money} width="100%"/>
                                </Col>
                                <Col xs={8}>
                                    <h4 className="text-primary">{props.t("Upload proof of payment.")}</h4>
                                    <p>
                                        {props.t("Upload the proof of the consignment or transaction of the payment of your capital units.")}
                                    </p>
                                    <div style={{width: '100%', height: '31px'}}>
                                        {
                                            block.transfer ?
                                                <a onClick={uploadTransfer} href="#" className="btn btn-block btn-agronomy-outline"><i
                                                    className="fas fa-check-circle"/> File Uploaded.</a> :
                                                <a onClick={uploadTransfer} href="#"
                                                   className="btn btn-block btn-agronomy-outline">Click to upload a
                                                    file.</a>
                                        }
                                    </div>
                                    <input onChange={(e) => handleChange(e, 'Transfer')} ref={transferRef} type="file" style={{display: 'none'}}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export default withNamespaces()(UploadDoc)
