import React, { Component, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Table,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Carousel from "react-gallery-carousel";
import Purchase from "./Components/Purchase";
import PaymentInfo from "./Components/PaymentInfo";
import { useParams } from "react-router-dom";
import axios from "axios";
import { withNamespaces } from "react-i18next";
import { useSelector } from "react-redux";

const Project = (props) => {
  const { id: projectId } = useParams();
  const [project, setProject] = useState({});

  const { user: currentUser } = useSelector((state) => state.user);

  const [purchase, setPurchase] = useState(false);
  const togglePurchase = () => setPurchase(!purchase);

  const [payment, setPayment] = useState(false);
  const togglePayment = () => setPayment(!payment);

  const breadcrumbItems = [
    { title: "Agronomy", link: "/" },
    { title: "Projects", link: "/projects" },
    { title: "Project Name", link: "#" },
  ];

  const getProject = () => {
    axios
      .get(process.env.REACT_APP_AGRONOMY_URL + "api/projects/" + projectId)
      .then((response) => {
        setProject(response.data.project);
      })
      .catch((error) => console.log(error));
  };

  const images = !project.images
    ? []
    : project.images.map((image) => ({
        // eslint-disable-next-line no-unused-expressions
        src: `${image.path}`,
      }));

  const [block, setBlock] = useState(null);

  useEffect(() => {
    getProject();
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Project" breadcrumbItems={breadcrumbItems} />
          <h2 className="text-primary">{project.name}</h2>
          <h5 style={{ fontWeight: 100 }}>
            <i className="ri-map-pin-3-fill text-primary" />
            {project.ubication
              ? project.ubication.city +
                ", " +
                project.ubication.state +
                ", " +
                project.ubication.country
              : ""}
          </h5>
          <hr />

          <Row className="justify-content-center">
            <Col md={7}>
              <Carousel images={images} />
            </Col>
            <Col md={12} className="my-3">
              <h3 className="text-primary">{props.t("Description")}</h3>
              <div dangerouslySetInnerHTML={{ __html: project.description }} />
            </Col>
          </Row>

          <Row className="my-4 py-2 bg-white">
            <Col className="py-4" md={12} style={{ textAlign: "center" }}>
              <h1 className="text-primary">{props.t("Join us now")}</h1>
              <h4 className="text-secondary" style={{ fontWeight: "lighter" }}>
                {props.t("Buy capital units before they are sold out")}
              </h4>
            </Col>
            <Col md={6} style={{ textAlign: "center" }}>
              <h1>
                {project.current ? project.current.available_capital_units : ""}{" "}
                /{" "}
                <small className="text-secondary">
                  {project.capital_units}
                </small>
              </h1>
              <h4 className="text-primary">
                {props.t("Available Capital Units")}
              </h4>
            </Col>
            <Col
              md={6}
              className="my-md-0"
              style={{ textAlign: "center", marginTop: "30px" }}
            >
              <h1 className="text-primary">
                $ {project.current ? project.current.capital_unit_value : ""}{" "}
                <small>USD</small>
              </h1>
              <h4 className="text-secondary">{props.t("Per Capital Unit")}</h4>
            </Col>
            <Col md={12} style={{ textAlign: "center" }}>
              <a
                onClick={togglePurchase}
                className={
                  "my-4 btn btn-lg btn-primary btn-block " +
                  (currentUser.expired ? "disabled" : "")
                }
              >
                {!currentUser.expired
                  ? props.t("Purchase your Capital Units")
                  : "Your subscription expired, renew for purchase Capital Units"}
              </a>
            </Col>
          </Row>

          {project.videos && project.videos.length ? (
            <Row className="justify-content-center py-4">
              <Col className="py-4" md={12} style={{ textAlign: "center" }}>
                <h3 className="text-primary">{props.t("More About")},</h3>
                <h4
                  className="text-secondary"
                  style={{ fontWeight: "lighter" }}
                >
                  {props.t(
                    "See the next videos for more information about this beautiful project."
                  )}
                </h4>
              </Col>
              {project.videos.map((video) => (
                <Col md={6}>
                  <div className="ratio ratio-16x9">
                    <iframe
                      title="video4"
                      className="embed-responsive-item"
                      src={video.url}
                    />
                  </div>
                  <h3 className="mt-2 text-center text-primary">
                    {video.title}
                  </h3>
                </Col>
              ))}
            </Row>
          ) : (
            <></>
          )}

          {project.documents && project.documents.length ? (
            <Row className="justify-content-center py-4 bg-white">
              <Col md={12}>
                <h3 className="text-primary">{props.t("Documents")}</h3>

                <Container>
                  <Table className="mx-5">
                    {project.documents.map((doc) => (
                      <tr>
                        <td>{doc.title}</td>
                        <td>
                          <a
                            style={{ backgroundColor: "#71E002" }}
                            href={`${doc.path}`}
                            className="my-4 btn btn-primary btn-block"
                          >
                            {props.t("See Document")}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </Table>
                </Container>
              </Col>
            </Row>
          ) : (
            <></>
          )}

          {project.ubication && project.ubication.map ? (
            <Row className="justify-content-center bg-white py-4">
              <Col className="py-4" md={12} style={{ textAlign: "center" }}>
                <h3 className="text-primary">{props.t("Ubication")}</h3>
                <h4
                  className="text-secondary"
                  style={{ fontWeight: "lighter" }}
                >
                  {props.t("See the location of the project and nearby sites.")}
                </h4>
              </Col>
              <Col>
                <iframe
                  src={project.ubication.map}
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                />
              </Col>
              <Col
                md={12}
                className="d-md-none"
                style={{ textAlign: "center" }}
              >
                <a
                  onClick={togglePurchase}
                  className={
                    "my-4 btn btn-lg btn-primary btn-block " +
                    (currentUser.expired ? "disabled" : "")
                  }
                >
                  {!currentUser.expired
                    ? props.t("Purchase your Capital Units")
                    : "Your subscription expired, renew for purchase Capital Units"}
                </a>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Container>
      </div>

      <Purchase
        setBlock={setBlock}
        open={purchase}
        toggle={togglePurchase}
        togglePayment={togglePayment}
        project={project}
      />

      {block ? (
        <PaymentInfo
          purchase
          block={block}
          show={payment}
          toggle={togglePayment}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default withNamespaces()(Project);
